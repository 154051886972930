// Pagination.js
import React from 'react';
import './Pagination.css';
const getPageNumbers = (totalPages, currentPage) => {
    const pageNumbers = [];
  
    // Display the first and last page numbers
    pageNumbers.push(1);
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }
  
    // Display the current page and the pages around it
    const maxPagesToShow = 5; // Change this value to control the number of pages to show
    const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 2);
    const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages - 1);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    // Remove duplicates and sort the page numbers
    return [...new Set(pageNumbers)].sort((a, b) => a - b);
  };
  


const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
const totalPages = Math.ceil(totalItems / itemsPerPage);
const pageNumbers = getPageNumbers(totalPages, currentPage);

return (
    <div >
    {pageNumbers.map(number => (
        <button 
        key={number}
        onClick={() => onPageChange(number)}
        className={`${number === currentPage ? 'active' : ''} transparent-button`}
        >
        {number}
        </button>
    ))}
    </div>
);
};


export default Pagination;