import React, { useState } from 'react';
import yesno from "yesno-dialog";

import { FaMoneyBillAlt, FaCreditCard } from 'react-icons/fa';
import IconContainer from './IconContainer';
import './GasStationCard.css';

const GasStationCard = ({ station, onUpdatePrice }) => {
  const [newPrice, setNewPrice] = useState('');
  let confirmed = false;

  const handlePriceChange = (event) => {
    setNewPrice(event.target.value);
  };

  const handleUpdatePrice = async () => {
    const parsedPrice = parseFloat(newPrice);
    if (!isNaN(parsedPrice)) {
      
      const isCash = await yesno({
        labelYes:"תשלום במזמומן בלבד",
        labelNo: "גם באשראי",
        bodyText: "מזומן בלבד?"
      });
        
      onUpdatePrice(station.id, parsedPrice, isCash);
      // Clear the input field after updating the price
      setNewPrice('');
    } else {
      // Handle invalid input
      alert('הזן ערך נכון למחיר');
    }
  };
  const handleVerifyPrice = () => {
    // check that the price station.disel_price is correct
      const currentPrice = station.disel_price;
      const message = `המחיר הנוכחי הוא: ${currentPrice}\nהאם המחיר מעודכן?`;
      confirmed = window.confirm(message);
      if (confirmed) {
        onUpdatePrice(station.id, currentPrice);
      }
    

    if (confirmed){
    onUpdatePrice(station.id, station.disel_price);


    }
  };

  const handleNavigateToStation = () => {
    // Replace latitude and longitude with actual coordinates of the gas station
    const latitude = station.lat;
    const longitude = station.lng;
  
    // Construct the Waze URL with the coordinates
    const wazeUrl = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;
  
    // Open Waze using the constructed URL
    window.open(wazeUrl, '_blank');
  };

  return (
    <div className="gas-station-card">
      <h3>{station.name}</h3>
      <p>חברה: {station.company}</p>
      <p>מחיר דיזל:<b>{station.disel_price !== 0 ? station.disel_price : 'טרם דווח'}</b>
      {station.disel_price !== 0 && (

      <span className='price-container'>

      

      {station.isCash ? (
          // <IconContainer className="icon" icon={<MdOutlineCreditCardOff />}></IconContainer>
          <IconContainer className="icon" icon={<FaMoneyBillAlt />}></IconContainer>
        ) : (
          <IconContainer className="icon" icon={<FaCreditCard />}></IconContainer>
        )}
      </span>
      )}
      </p>
      <p>כתובת: {station.address}</p>
      <p>זמן עדכון אחרון: {station.time_modified}</p>

      <p>טלפון: <a href={`tel:${station.telephone}`}>{station.telephone}</a></p>
      {station.distance_km !== null && station.distance_km !== undefined && (
        <p>מרחק: {station.distance_km.toFixed(2)} ק"מ</p>
      )}
      <input
        type="text"
        placeholder="דווח מחיר חדש"
        value={newPrice}
        onChange={handlePriceChange}
      />
      <button onClick={handleUpdatePrice}>עדכון מחיר</button>
      <button className="verify-button" onClick={handleVerifyPrice}>אמת מחיר</button>
      <button className="verify-button" onClick={handleNavigateToStation}>נווט לתחנה</button>

      
      
    </div>
  );
};

export default GasStationCard;
