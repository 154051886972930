import React from 'react';
import './IconContainer.css';

const IconContainer = ({ icon, children }) => (
  <div className="icon-container">
    {icon}
    <span>{children}</span>
  </div>
);

export default IconContainer;