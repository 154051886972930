import React, { useState, useEffect } from 'react';
import './App.css';
import GasStationCard from './GasStationCard';
import Pagination from './Pagination';

import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const [gasStations, setGasStations] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [currentPage, setCurrentPage] = useState(1);




  useEffect(() => {
    document.title = "השוואת מחירי הסולר"
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    fetchGasStations();
  }, []);

  const fetchGasStations = () => {
    setLoading(true);
    fetch('/api/all_stations')
      .then(response => response.json())
      .then(data => {
        setGasStations(data);
        setLoading(false);
      })
      .catch(error => console.error('Error:', error));
      setLoading(false);
  };

  const getUserLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        searchByLocation(latitude, longitude);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  const searchByLocation = (latitude, longitude) => {
    setLoading(true);
    fetch(`/api/search_by_location?latitude=${latitude}&longitude=${longitude}`)
      .then(response => response.json())
      .then(data => {
        setGasStations(data);
        setLoading(false);
      })
      .catch(error => console.error('Error:', error));
      setLoading(false);
  };

  const searchByCity = (city) => {
    setLoading(true);
    fetch(`/api/search_by_city?city=${city}`)
      .then(response => response.json())
      .then(data => {
        setGasStations(data);
        setLoading(false);
      })
      .catch(error => console.error('Error:', error));
      setLoading(false);
      
  };

  const updatePrice = (stationId, newPrice, isCash) => {
    setLoading(true);
    console.log(isCash);

    fetch('/api/update_price', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `station_id=${stationId}&new_price=${newPrice}&is_cash=${isCash}`
    })
    .then(response => {
      if (response.ok) {
        setLoading(false);
        return fetch(`/api/get_station_data/${stationId}`);
      } else {
        setLoading(false);

        throw new Error('Failed to update price');
      }
    })
    .then(response => response.json())
    .then(data => {
      const updatedStations = gasStations.map(station => {
        if (station.id === stationId) {
          return {
            ...station,
            disel_price: data.disel_price,
            time_modified: data.time_modified,
            isCash: data.is_cash
          };
        }
        return station;
      });
      setGasStations(updatedStations);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const getStationsForCurrentPage = () => {
    const itemsPerPage = 7;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentStations = gasStations.slice(startIndex, endIndex);
    return currentStations;
  };
  return (
    
    <div className="App">
    <header className="App-header">
      <h1>ברוכים הבאים לאתר השוואת מחירי הסולר!</h1>
      <p>
        מחיר הסולר משתנה בין התחנות השונות, ותדלוק במחיר זול יכול להוות חסכון אדיר. מטרת האתר היא לאגד ולשתף את המחירים השונים במזומן ובאשראי של מחירי הסולר.
      </p>
    </header>
      <div>
        <input type="text" id="cityInput" placeholder="הזן עיר..." />
        <button onClick={() => searchByCity(document.getElementById('cityInput').value)}>חיפוש</button>
        <div>

        <button onClick={getUserLocation}>התחנות הקרובות אלי</button>
        <span></span>
        <button onClick={fetchGasStations}>התחנות הזולות ביותר</button>
        </div>
      </div>
      {loading ? ( // Display loading animation if loading state is true
       <div className="loading-animation">
        Loading......
        <Spinner animation="border" variant="primary" />

        </div>
      ) : (
        <>
          {isMobile ? (
            
           <div>
           <Pagination
      totalItems={gasStations.length}
      itemsPerPage={7}
      currentPage={currentPage}
      onPageChange={handlePageChange}
    />

           {getStationsForCurrentPage().map(station => (
             <GasStationCard
               key={station.id}
               station={station}
               onUpdatePrice={updatePrice}
             />
           ))}
         </div>
          ) : (
            <div>This application is supported only on mobile phones</div>
          )}
        </>
      )}
      <Pagination
      totalItems={gasStations.length}
      itemsPerPage={7}
      currentPage={currentPage}
      onPageChange={handlePageChange}
    />
    <div className="about-us">
      <h2>אודותינו</h2>
      <p>
        אתר זה נוצר במטרה לסייע לצרכנים למצוא את מחירי הסולר הזולים ביותר בסביבתם.
      </p>
      <p>
      כל הזכויות שמורות לצוות diesel-pricing.online
      שימוש באתר זה כפוף לתנאי השימוש ולמדיניות הפרטיות שלנו.
      לצורך מסחרי ושימוש ב API יש ליצור קשר עם צוות האתר.

      </p>
            <p>
            support@diesel-pricing.online
            </p>
    
    </div>
    </div>
  );}

export default App;